/* global NativeStorage */

function parseValue(value) {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

function stringifyValue(value) {
  if (typeof value === "object") {
    return JSON.stringify(value);
  }
  return `${value}`;
}

const webLocalStorage = {
  setItem: (key, value) => localStorage.setItem(key, stringifyValue(value)),
  getItem: (key) => parseValue(localStorage.getItem(key)),
  removeItem: (key) => localStorage.removeItem(key),
  getKeys: () => Object.keys(localStorage),
};

const webLocalStorageAsync = {
  setItem: async (key, value) => localStorage.setItem(key, stringifyValue(value)),
  getItem: async (key) => parseValue(localStorage.getItem(key)),
  removeItem: async (key) => localStorage.removeItem(key),
  getKeys: async () => Object.keys(localStorage),
};

function getLocalStorage() {
  if (window.electron) {
    return {
      setItem: window.electron.setItem,
      getItem: window.electron.getItem,
      removeItem: window.electron.removeItem,
      getKeys: window.electron.getKeys,
    };
  }
  if (window.cordova) {
    return {
      setItem: (key, value) => new Promise((resolve, reject) =>
        NativeStorage.setItem(key, value, resolve, reject)),
      getItem: (key) => new Promise((resolve) =>
        NativeStorage.getItem(key, resolve, () => resolve(null))),
      removeItem: (key) => new Promise((resolve, reject) =>
        NativeStorage.remove(key, resolve, reject)),
      getKeys: () => new Promise((resolve, reject) =>
        NativeStorage.keys(resolve, reject)),
    };
  }
  return webLocalStorageAsync;
}

const { setItem, getItem, removeItem, getKeys } = getLocalStorage();

export { setItem, getItem, removeItem, getKeys, webLocalStorage };
